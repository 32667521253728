import call from '../../assets/icon/pricingCallMobile.svg';

import { openStore } from '../openStore/openStore';

import s from './pricing.module.scss';

export const Pricing = () => (
  <section className={s.content} id="Pricing">
    <div className={s.wrapper}>
      <span className={s.badge}>ChargeMate&apos;s Friendly Pricing</span>
      <h2 className={s.title}>Affordable Charging for Everyone</h2>
      <p className={s.subtitle}>
        Enjoy one-hour Powerbank rental for just $1 with ChargeMate. Explore our friendly tariffs and find your perfect charging mate.
      </p>
    </div>
    <div className={s.cardWrapper}>
      <div className={`${s.card} ${s.card1}`}>
        <img alt="Your Helper" className={s.call} src={call} />
        <div className={s.card_title}>
          <span>$1/hour</span>
          <h3>Hourly Power Boost</h3>
        </div>
        <p className={s.card_description}>
          For those quick bursts of energy, our hourly rental option is perfect.
          At just $1 per hour, you can keep your devices charged without any interruption.
        </p>
        <button className={s.card_button} type="button" onClick={openStore}>Get the App</button>
      </div>
      <div className={`${s.card} ${s.card2}`}>
        <div className={s.card_title}>
          <span>$4.99/day</span>
          <h3>Daily Charge Delight</h3>
        </div>
        <p className={s.card_description}>
          Planning a full day of activities? Opt for our daily rental option at only $4.99.
          Enjoy unlimited charging throughout the day, ensuring you&apos;re powered up from sunrise to sunset.
        </p>
        <button className={s.card_button} type="button" onClick={openStore}>Get the App</button>
      </div>
      <div className={`${s.card} ${s.card3}`}>
        <div className={s.card_title}>
          <h3>Or choose the plan inside the App</h3>
        </div>
        <div className={s.card_description}>
          <ul className={s.list__wrapper}>
            <li className={s.list}>
              <div className={s.list_title__wrapper}>
                <h5>Starter Plan</h5>
                <p className={s.list_title__fractionally}>$5/week</p>
              </div>
              <div className={s.list_price__wrapper}>
                <p className={s.list_price__title}>Weekly Plan</p>
                <p className={s.list_price__total}>$22.5/month</p>
              </div>
            </li>
            <hr />
            <li className={s.list}>
              <div className={s.list_title__wrapper}>
                <h5>Most popular</h5>
                <p className={s.list_title__fractionally}>$15/month</p>
              </div>
              <div className={s.list_price__wrapper}>
                <p className={s.list_price__title}>Monthly Plan</p>
                <p className={s.list_price__total}>$180/year</p>
              </div>
            </li>
            <hr />
            <li className={s.list}>
              <div className={s.list_title__wrapper}>
                <h5>Most profitable</h5>
                <p className={s.list_title__fractionally}>$150/year</p>
              </div>
              <div className={s.list_price__wrapper}>
                <p className={s.list_price__title}>Annual Plan</p>
                <p className={s.list_price__total}>$12.5/month</p>
              </div>
            </li>
          </ul>
        </div>
        <button className={s.card_button} type="button" onClick={openStore}>Get the App</button>
      </div>
    </div>
  </section>
);
