export function openStore() {
  const { userAgent } = navigator;
  const lower = userAgent.toLowerCase();

  if (lower.includes('android')) {
    // Open Google Play in a new tab
    window.open('https://play.google.com/store/apps/details?id=com.chargemate.charge&hl=ru&gl=US', 'blank');
  } else if (lower.includes('mac')) {
    // Open App Store in a new tab
    window.open('https://apps.apple.com/app/chargemate-us/id6468920265', 'blank');
  } else {
    window.open('https://play.google.com/store/apps/details?id=com.chargemate.charge&hl=ru&gl=US', 'blank');
  }
}
