import { Link } from 'react-scroll';

import play from '../../assets/icon/play-circle.svg';
import check from '../../assets/icon/Check.svg';

import { openStore } from '../openStore/openStore';

import s from './hero.module.scss';

export const Hero = () => (
  <section className={s.hero_wrapper} id="Welcome">
    <div className={s.content}>
      <div className={s.content_description}>
        <h1>Rent a powerbank for the price of a coffee! In the right place at the right time!</h1>
        <p className={s.subtitle}>Never be stranded in the low-battery zone again. Experience seamless connectivity with ChargeMate&apos;s 24/7 power bank rentals. Stay charged, stay connected! </p>
        <div className={s.content_button}>
          <Link
            smooth
            spy
            activeClass={s.active}
            className={s.partner}
            duration={500}
            offset={-70}
            to="ContactUs"
          >
            <img alt="icon play" src={play} />
            Become A Partner
          </Link>
          <button className={s.app} type="button" onClick={openStore}>Download the App</button>
          <p>Available in AppStore and Google Play</p>
        </div>
      </div>
      <div className={s.checkItem}>
        <ul>
          <li>
            <img alt="icon check" src={check} />
            <span>Power up your phone, tablet, headphones, and laptop.</span>
          </li>
          <li>
            <img alt="icon check" src={check} />
            <span>Just $1 per hour for uninterrupted connectivity.</span>
          </li>
          <li>
            <img alt="icon check" src={check} />
            <span>Pick up at one location, return anywhere.</span>
          </li>
        </ul>
      </div>
    </div>
    <div className={s.intro_wrapper}>
      <iframe
        allow="autoplay"
        className={s.intro}
        frameBorder="0"
        loading="lazy"
        src="https://player.vimeo.com/video/861567585?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1&autoplay=1&loop=1&muted=1&autopause=0&title=0&byline=0&portrait=0"
        title="chargemate hero"
      />
    </div>
  </section>
);
