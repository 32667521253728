import { FC } from 'react';

import s from '../howToUse.module.scss';

interface ButtonsProps {
  changeSlide: (slideNumber: number) => void,
  currentSlide: number,
}

const HEADERS = ['Locate the closest ChargeMate station in the App',
  'Simply scan the station\'s QR-code and grab a powerbank',
  'Return the powerbank to any ChargeMate station. We\'re all about convenience!'];

const BODY = ['After a quick set up you\'ll see a map of the nearest stations equipped with powerbanks',
  'You\'ll find out how many powerbanks you can take and turn in, as well as hints to locate the station',
  'Simply insert the powerbank into the station to finish renting'];

export const SlideButtons:FC<ButtonsProps> = ({ changeSlide, currentSlide }) => (
  <div className={s.animation_description}>
    {window.innerWidth > 767
      && (
        <>
          <button
            className={`${s.animation_description__wrapper} ${s.description1}`}
            type="button"
            onClick={() => changeSlide(1)}
          >
            <h4 className={s.animation_description__title}>
              {HEADERS[0]}
            </h4>
            <p className={s.animation_description__description}>
              {BODY[0]}
            </p>
          </button>
          <button
            className={`${s.animation_description__wrapper} ${s.description2}`}
            type="button"
            onClick={() => changeSlide(2)}
          >
            <h4 className={s.animation_description__title}>
              {HEADERS[1]}
            </h4>
            <p className={s.animation_description__description}>
              {BODY[1]}
            </p>
          </button>
          <button
            className={`${s.animation_description__wrapper} ${s.description3}`}
            type="button"
            onClick={() => changeSlide(3)}
          >
            <h4 className={s.animation_description__title}>
              {HEADERS[2]}
            </h4>
            <p className={s.animation_description__description}>
              {BODY[2]}
            </p>
          </button>
        </>
      )}
    {window.innerWidth <= 767 && (currentSlide === 1)
      && (
        <button className={`${s.animation_description__wrapper} ${s.description1}`} type="button">
          <h4 className={s.animation_description__title}>{HEADERS[0]}</h4>
          <p className={s.animation_description__description}>
            {BODY[0]}
          </p>
        </button>
      )}
    {window.innerWidth <= 767 && (currentSlide === 2) && (
      <button className={`${s.animation_description__wrapper} ${s.description2}`} type="button">
        <h4 className={s.animation_description__title}>{HEADERS[1]}</h4>
        <p className={s.animation_description__description}>
          {BODY[1]}
        </p>
      </button>
    )}
    {window.innerWidth <= 767 && (currentSlide === 3) && (
      <button className={`${s.animation_description__wrapper} ${s.description3}`} type="button">
        <h4 className={s.animation_description__title}>{HEADERS[2]}</h4>
        <p className={s.animation_description__description}>
          {BODY[2]}
        </p>
      </button>
    )}
  </div>
);
