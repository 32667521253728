const questions: { question: string, answer: string }[] = [
  {
    question: 'What is ChargeMate?',
    answer: 'ChargeMate is a leading power bank rental service that offers convenient on-the-go charging solutions for mobile devices.',
  },
  {
    question: 'How do I rent a Powerbank from ChargeMate?',
    answer: 'Renting a Powerbank is easy! Just find a ChargeMate station near you, pick up a Powerbank, and start charging. You can also download our app for a seamless rental experience.',
  },
  {
    question: 'Where can I find ChargeMate stations?',
    answer: 'ChargeMate stations are conveniently located in cafes, offices, events, and other public places. You can find the nearest station using our app or by looking for the ChargeMate sign.',
  },
  {
    question: 'How much does it cost to rent a Powerbank?',
    answer: 'ChargeMate offers 24-hour Powerbank rentals for just 99 ₽. For detailed pricing information and tariffs in different locations, please check our Pricing section or download the app.',
  },
  {
    question: 'Can I return the Powerbank to a different station?',
    answer: 'Absolutely! With ChargeMate, you can return the Powerbank to any of our stations across the city. We\'re all about flexibility and convenience.',
  },
  {
    question: 'Is my device compatible with ChargeMate Powerbanks?',
    answer: 'ChargeMate Powerbanks come with various charging cables, making them compatible with most smartphones and devices. If you have specific concerns, please contact our support team.',
  },
  {
    question: 'What if I have an issue with the Powerbank?',
    answer: 'We\'re here to help! If you encounter any problems, please reach out to our support team through the app or contact us directly. Your ChargeMates are always ready to assist you.',
  },
  {
    question: 'How do I become a ChargeMate partner or host a station?',
    answer: 'Interested in joining the ChargeMate family? Visit our Partners page or contact us to learn more about hosting a station or becoming a partner.',
  },
  {
    question: 'Is ChargeMate available in my city?',
    answer: 'ChargeMate is rapidly expanding to various cities. Check our app or website to see if we\'re available in your area. If not, stay tuned! We might be coming to your city soon.',
  },
  {
    question: 'How do I download the ChargeMate app?',
    answer: 'You can download the ChargeMate app from the App Store or Google Play. Just search for "ChargeMate" and follow the instructions to install.',
  },
  {
    question: 'What are the terms and conditions for using ChargeMate?',
    answer: 'You can find our complete terms and conditions on our website. We encourage you to read them to understand our policies and ensure a smooth ChargeMate experience.',
  },
  {
    question: 'How do I contact ChargeMate support?',
    answer: 'Need a ChargeMate? We\'re here for you! You can contact us through the app, fill out our contact form on the website, or call our hotline number. We\'re always ready to assist you.',
  },
];

export default questions;
