import s from './howToUse.module.scss';
import { Slider } from './Slider/Slider';

export const HowToUse = () => (
  <section className={s.content} id="HowToUse">
    <div className={s.wrapper}>
      <span className={s.badge}>Charging Made Easy</span>
      <h2 className={s.title}>Stay Charged Anytime, Anywhere</h2>
      <p className={s.subtitle}>
        Join the ChargeMate family and enjoy hassle-free charging in three simple steps. We&apos;re here to make sure you stay connected.
      </p>
    </div>
    <div className={s.animation_container}>
      <Slider />
    </div>
  </section>
);
