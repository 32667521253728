import appStore from '../../assets/icon/appStore.svg';
import googlePlay from '../../assets/icon/googlePlay.svg';
import iphone from '../../assets/img/cta.webp';
import badge from '../../assets/img/ctaBadge.webp';
import badge2 from '../../assets/img/ctaBadge2.webp';
import badge3 from '../../assets/img/ctaBadge3.webp';
import badge4 from '../../assets/img/ctaBadge4.webp';

import s from './cta.module.scss';

export const CTA = () => (
  <section className={s.conteiner} id="App">
    <div className={s.wrapper}>
      <div className={s.content}>
        <h3 className={s.title}>Ready to become a ChargeMate?</h3>
        <p className={s.subtitle}>Download the App for your device and forget about low energy vibe.</p>
        <div className={s.btns}>
          <a
            className={s.btn}
            href="https://apps.apple.com/app/chargemate-us/id6468920265"
            rel="noreferrer"
            target="_blank"
          >
            <img alt="app store" loading="lazy" src={appStore} />
          </a>
          <a
            className={s.btn}
            href="https://play.google.com/store/apps/details?id=com.chargemate.charge&hl=ru&gl=US"
            rel="noreferrer"
            target="_blank"
          >
            <img alt="google play" loading="lazy" src={googlePlay} />
          </a>
        </div>
      </div>
      <div className={s.imgs}>
        <img alt="iphone" className={s.iphone} src={iphone} />
        <div className={s.badge__wrapper}>
          <img alt="Decorative" loading="lazy" src={badge} />
          <img alt="Decorative" loading="lazy" src={badge2} />
          <img alt="Decorative" loading="lazy" src={badge3} />
          <img alt="Decorative" loading="lazy" src={badge4} />
        </div>
      </div>
    </div>
  </section>
);
